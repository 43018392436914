<template>
    <div class="goods-group-page">
        <div class="title">
            商品分组
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加商品分组</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="search" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table :data="goodsGroupList"
              border row-key="group_id" align="left">
                <el-table-column v-for="(item, index) in colList"
                                :key="`col_${index}`"
                                :prop="item.prop"
                                :label="item.label">
                                <template slot-scope="scope">
                                        <span v-if="item.label === '创建时间' || item.label === '更新时间'">
                                            {{scope.row[item.prop] | datefmt('YYYY-MM-DD HH:mm:ss') }}
                                        </span>
                                        <span v-else>{{ scope.row[item.prop] }}</span>
                                </template>
                </el-table-column>
                <el-table-column label="编辑">
                    <template slot-scope="scope">
                            <span>
                            <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                            </span>
                            <span>
                                <el-popconfirm
                                    confirmButtonText='好的'
                                    cancelButtonText='取消'
                                    icon="el-icon-info"
                                    iconColor="red"
                                    @confirm="deleteGoodsGroup(scope.row.group_id)"
                                    title="确定删除该商品分组吗？">
                                    <el-button slot="reference" type="text" size="small">删除</el-button>
                                </el-popconfirm>
                            </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}商品分组`" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addGoodsGroupDialog">
            <el-form :model="addGoodsGroupData" :rules="rules" ref="addGoodsGroupForm" label-width="78px">
                <el-form-item label="分组名称" prop='groupName'>
                    <el-input v-model="addGoodsGroupData.groupName" size="medium"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addGoodsGroupDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveGoodsGroup('addGoodsGroupForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { fork } from 'child_process'
import Sortable from 'sortablejs'
export default {
    name: 'GoodsGroup',
    data () {
        return {
            // 验证规则
            rules: {
                groupName: [
                    { required: true, message: '请输入分组名称', trigger: 'change' }
                ]
            },

            // 商品分组列表
            goodsGroupList: [],
            colList: [
                {
                    label: '名称',
                    prop: 'group_name'
                },
                {
                    label: '排序',
                    prop: 'group_sort'
                },
                {
                    label: '创建时间',
                    prop: 'create_time'
                },
                {
                    label: '更新时间',
                    prop: 'update_time'
                }
            ],
            page: 1,
            size: 10,
            total: 0,
            search: '',

            // 新增/编辑数据
            addGoodsGroupData: {
                groupName: ''
            },
            addGoodsGroupDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.rowDrop()
        this.getGoodsGroupList()
    },
    methods: {
        // 获取商品分组列表
        getGoodsGroupList () {
            this.axios.post('/good/group/list', {
                page: this.page,
                size: this.size,
                search: this.search
            }).then((res) => {
                this.goodsGroupList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getGoodsGroupList()
        },
        //行拖拽
        rowDrop() {
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            const _this = this
            Sortable.create(tbody, {
                onEnd({ newIndex, oldIndex }) {
                    console.log("拖动了行","当前序号: "+newIndex, '之前排序：' + oldIndex)
                    const currRow = _this.goodsGroupList.splice(oldIndex, 1)[0]
                    console.log(currRow)
                    _this.goodsGroupList.splice(newIndex, 0, currRow)
                   
                    let sortList = []
                    _this.goodsGroupList = _this.goodsGroupList.map((item, index) => {
                        item.group_sort = index + 1
                        sortList.push({
                            group_id: item.group_id,group_sort: index + 1
                        })
                        return item
                    })
                    _this.changeSort(sortList)
                }
            })
        },
        /**
         * 拖拽行后排序
         * @param {Array} param 排序参数
         */
        changeSort(param){
            this.axios.post('/good/group/sort', {
                groups: param
            }).then((res) => {
                console.log(res)
                this.getGoodsGroupList()
            }).catch(() => {
                // console.log(error)
            })
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param goodsGroupData 为编辑时，商品分组数据
         */
        openAddDialog (isEdit, goodsGroupData) {
            this.isEdit = isEdit
            this.addGoodsGroupDialog = true

            if (isEdit) {
                this.addGoodsGroupData = {
                    groupId: goodsGroupData.group_id,
                    groupName: goodsGroupData.group_name
                }
            } else {
                this.addGoodsGroupData = {
                    groupName: ''
                }

                this.$nextTick(() => {
                    this.$refs.addGoodsGroupForm.clearValidate()
                })
            }

            console.log(goodsGroupData)
        },

        /**
         * 删除商品分组
         * @param editGoodsGroupId 删除ID
         */
        deleteGoodsGroup (editGoodsGroupId) {
            console.log("=============================")
            console.log(editGoodsGroupId)
            this.axios.post('/good/group/delete', {
                group_id: editGoodsGroupId
            }).then((res) => {
                if (res) {
                    this.getGoodsGroupList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存商品分组数据
         * @param formName 表单名称
         */
        sureSaveGoodsGroup (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/good/group/update' : '/good/group/add'
                    const { groupName, groupId } = { ...this.addGoodsGroupData }
                    console.log(groupId)
                    this.axios.post(requestUrl, {
                        group_id: groupId,
                        group_name: groupName
                    }).then((res) => {
                        console.log(res)
                        this.addGoodsGroupDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}商品分组`)
                        this.getGoodsGroupList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .goods-group-page{
        .title{
            padding: 14px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
